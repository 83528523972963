<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { cpfCnpj } from '@/utils/validator'
import AuthWrapper from '@/views/auth/components/AuthWrapper'
import Action from '@/components/Action'
import InputField from '@/components/InputField'
import FormResponseMessage from '@/components/FormResponseMessage'

export default {
  name: 'Login',

  components: {
    AuthWrapper,
    Action,
    InputField,
    FormResponseMessage,
  },

  data () {
    return {
      loading: false,
      formData: {
        user: null,
        password: null,
      },
      response: {
        message: null,
        type: null,
      },
    }
  },

  validations: {
    formData: {
      user: {
        required,
        cpfCnpj,
      },
      password: {
        required,
      },
    },
  },

  methods: {
    ...mapActions('auth', ['login']),

    clearForm () {
      this.formData = {
        user: null,
        password: null,
      }
    },

    clearMessage () {
      this.response = {
        message: null,
        type: null,
      }
    },

    handleSubmit () {
      this.$v.$touch()
      if (this.$v.$invalid) { return }
      this.clearMessage()
      this.loading = true
      this.login(this.formData).then(() => {
        this.$router.push({ name: 'home' })
      }).catch((response) => {
        this.loading = false
        this.clearForm()
        this.$v.formData.user.$reset()
        this.$v.formData.password.$reset()
        this.response.type = 'error'
        if (response.field === 'user') {
          this.$v.formData.user.$touch()
          this.response.message = 'CPF/CNPJ não encontrado. <br/>Verifique as informações fornecidas.'
        }
        if (response.field === 'password') {
          this.$v.formData.password.$touch()
          this.response.message = 'Senha incorreta. <br/>Verifique as informações fornecidas ou clique em Esqueci a senha.'
        }
      })
    },
  },
}
</script>

<template>
  <auth-wrapper>
    <form @submit.prevent="handleSubmit">
      <input-field
        v-model="formData.user"
        label="CPF/CNPJ"
        placeholder="Escreva aqui"
        :mask="['###.###.###-##', '##.###.###/####-##']"
        :validation="$v.formData.user"
      />
      <input-field
        v-model="formData.password"
        label="Senha"
        type="password"
        placeholder="Escreva aqui"
        :validation="$v.formData.password"
      />
      <div :class="$classes['forget-password']" style="display: none">
        <action
          variant="flat"
          color="primary"
          :to="{ name: 'auth.recover.password' }"
        >
          Esqueci senha
        </action>
      </div>
      <form-response-message
        v-if="response.message"
        :message="response.message"
        :type="response.type"
      />
      <div :class="$classes['form-actions']">
        <action
          full-width
          variant="contained"
          color="primary"
          type="submit"
          :loading="loading"
          :disabled="loading"
        >
          Entrar
        </action>
      </div>
    </form>
    <div :class="$classes['obs']" style="display: none">
      <p>Não tem cadastro?</p>
      <action
        variant="outlined"
        color="secondary"
        :url="'http://www.google.com.br'"
        target="_blank"
      >
        Cadastre-se
      </action>
    </div>
  </auth-wrapper>
</template>

<style module>
.forget-password {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.form-actions {
  margin-top: 25px;
}

.obs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
}

.obs p {
  margin-bottom: 0;
}

@media (max-width: 767px) {

}

@media (max-width: 390px) {
  .obs {
    margin-top: 20px;
    flex-direction: column;
  }

  .obs p {
    margin-bottom: 10px;
  }
}
</style>
