<script>
export default {
  name: 'FormResponseMessage',

  props: {
    message: {
      type: String,
      default: null,
    },

    type: {
      type: String,
      default: null,
    },
  },

  computed: {
    computedClasses () {
      return {
        [this.$classes['is-error']]: this.type === 'error',
        [this.$classes['is-success']]: this.type === 'success',
      }
    },
  },
}
</script>

<template>
  <div :class="[$classes['message'], computedClasses]" v-if="message">
    <p v-html="message"/>
  </div>
</template>

<style module>
.message {
  text-align: center;
  margin: 20px 0;
  padding: 0 15px;
}

.message.is-error {
  color: rgba(209,64,64,0.8);
}

.message.is-success {
  color: rgba(74,148,212,0.8);
}

.message p {
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 0;
}
</style>
