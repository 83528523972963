<script>
export default {
  name: 'AuthWrapper',
}
</script>

<template>
  <div :class="$classes['auth-wrapper']">
    <div
      v-if="$media.mobile"
      src="~@/assets/images/auth.png"
      :class="$classes['auth-wrapper-header']"
    />
    <div :class="$classes['box']">
      <img
        src="@/assets/images/logo.png"
        :class="$classes['logo']"
      >
      <slot />
    </div>
  </div>
</template>

<style module>
.auth-wrapper {
  background: url('~@/assets/images/auth.png') no-repeat;
  background-size: cover;
  height: 100vh;
}

.box {
  position: relative;
  top: 50%;
  left: 100px;
  transform: translateY(-50%);
  width: 380px;
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 10px 45px 30px 35px;
}

.logo {
  display: block;
  margin: 0 auto 20px;
  width: 100%;
  height: 110px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .box {
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

@media (max-width: 767px) {
  .auth-wrapper {
    background: none;
  }

  .auth-wrapper-header {
    background: url('~@/assets/images/auth.png') no-repeat;
    background-size: cover;
    width: 100%;
    height: 150px;
  }

  .box {
    position: static;
    transform: none;
    width: 100%;
    height: calc(100vh - 150px);
    box-shadow: none;
    border-radius: 0;
  }

  .logo {
    width: 200px;
    height: auto;
  }
}
</style>
