import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator'
import { helpers } from 'vuelidate/lib/validators'

export const cpf = (value) => !helpers.req(value) || cpfValidator.isValid(value)

export const cnpj = (value) => !helpers.req(value) || cnpjValidator.isValid(value)

export const phoneBR = (value) => !helpers.req(value) || /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/.test(value)

export const cpfCnpj = (value) => !helpers.req(value) || cpfValidator.isValid(value) || cnpjValidator.isValid(value)
